import { Injectable, OnDestroy } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { cloneDeep } from 'lodash-es';
import { filter, map, switchMap } from 'rxjs';
import { ICookieConsent } from 'src/app/api/modules/core/components/static/cookies/ICookieConsent';
import { ICookies } from 'src/app/api/modules/core/components/static/cookies/ICookies';
import { CookiesStorageConsentStatus, ICookiesStorage } from 'src/app/api/modules/core/components/static/cookies/ICookiesStorage';
import { createDigitaServiceError } from 'src/app/app-error';
import { ElfCombineQueries } from 'src/app/util/ElfCombineQueries';
import { ElfWrite } from 'src/app/util/ElfWrite';
import { CookiesModel } from './cookies.model';

/**
 * The Default State
 */
function initialState(): CookiesModel {
  return {
    configured: false,
    enable: undefined,
    localStorage: false,
    consent: null,
  };
}

/**
 * The Cookies Repository
 *
 * It belongs to the {@link CoreModule}.
 */
@Injectable({
  providedIn: 'root',
})
export class CookiesRepository implements OnDestroy {
  /**
   * The store.
   */
  private store = createStore(
    {
      name: `cookies`,
    },
    withProps<CookiesModel>(initialState()),
  );

  ////////////////////////////////////////////////////////////////////
  // INITIALIZE
  ////////////////////////////////////////////////////////////////////

  /**
   * Initializes the store with the provided configuration.
   *
   * @param configuration - The configuration from the server.
   * @param storage - The cookies storage from the local storage.
   */
  applyConfiguration(configuration?: ICookies, storage: ICookiesStorage | null = null) {
    // if the configuration is enabled
    let enable = false;
    if (configuration?.enable === true) {
      enable = true;
    }

    // if localstorage is enabled
    let localStorage = false;
    if (configuration?.localStorage === true) {
      localStorage = true;
    }

    // if there is a consent object
    let consent: ICookieConsent | null = null;
    if (configuration?.consent) {
      consent = cloneDeep(configuration.consent);
    }

    // process the consent object
    if (consent) {
      // if there is no message then throw an error
      if (!consent.message) {
        throw createDigitaServiceError(
          `Cookies`,
          `applyConfiguration`,
          `The "message" property is required for the cookie consent.`,
          `config`,
        );
      }

      // if there is an accept button enforce it to be a button
      if (consent.accept) {
        consent.accept.dialog = undefined;
        consent.accept.link = undefined;
        consent.accept.type = 'button';
      }

      // if there is an accept button enforce it to be a button
      if (consent.decline) {
        consent.decline.dialog = undefined;
        consent.decline.link = undefined;
        consent.decline.type = 'button';
      }

      // if there is a learn mode then ensure it is a link
      if (consent.learnMore) {
        if (!consent.learnMore.title) {
          throw createDigitaServiceError(
            `Cookies`,
            `applyConfiguration`,
            `The "title" property is required for the cookie consent.`,
            `config`,
          );
        }

        if (!consent.learnMore.href) {
          throw createDigitaServiceError(
            `Cookies`,
            `applyConfiguration`,
            `The "href" property is required for the cookie consent.`,
            `config`,
          );
        }

        if (!consent.learnMore.type) {
          consent.learnMore.type = 'external-blank';
        }
      }
    }

    // if there is a local storage property
    if (storage) {
      // if the user has already accepted cookies then it's enabled
      if (storage.consentStatus === CookiesStorageConsentStatus.ACCEPTED) {
        consent = null;
        enable = true;
      } else if (storage.consentStatus === CookiesStorageConsentStatus.DECLINED) {
        // if the user has already rejected then it's disabled
        enable = false;
      }
    }

    // update the store
    this.store.update(
      ElfWrite((state) => {
        state.configured = true;
        state.enable = enable;
        state.consent = consent;
        state.localStorage = localStorage;
      }),
    );
  }

  /**
   * Apply User Consent.
   */
  applyConsent() {
    this.store.update(
      ElfWrite((state) => {
        state.enable = true;
        state.consent = null;
      }),
    );
  }

  /**
   * Reject User Consent.
   */
  rejectConsent() {
    this.store.update(
      ElfWrite((state) => {
        state.enable = false;
        state.consent = null;
      }),
    );
  }

  /**
   * Clear the Cookies
   */
  clear() {
    const initial = initialState();
    this.store.update(
      ElfWrite((state) => {
        state.enable = initial.enable;
        state.consent = initial.consent;
      }),
    );
  }

  /**
   * Lifecycle Hook
   */
  ngOnDestroy() {
    this.store?.destroy();
  }

  ////////////////////////////////////////////////////////////////////
  // QUERIES
  ////////////////////////////////////////////////////////////////////

  /**
   * Has the Media Been Configured.
   *
   * This means a configuration has been passed to the component and has been successfully parsed and validated.
   */
  private _configured$ = this.store.pipe(select((state) => state.configured));

  /**
   * Is Cookies Enabled or Disabled?
   */
  private _enabled$ = this.store.pipe(select((state) => state.enable));

  /**
   * Get the Consent Object.
   */
  private _consent$ = this.store.pipe(select((state) => state.consent));

  /**
   * Get the Consent Object.
   */
  consent$ = this._configured$.pipe(
    filter((configured) => configured),
    switchMap(() => this._consent$),
  );

  /**
   * Is Local Storage Enabled?
   */
  private _localStorage$ = this.store.pipe(select((state) => state.localStorage));

  /**
   * Is Cookies Enabled or Disabled?
   */
  enabled$ = this._configured$.pipe(
    filter((configured) => configured),
    switchMap(() =>
      ElfCombineQueries([this._enabled$, this._localStorage$]).pipe(
        map(([enabled, localStorage]) => {
          return {
            enabled,
            localStorage,
          };
        }),
      ),
    ),
  );
}
