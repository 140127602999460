/**
 * If the User has accepted or declined the Cookies previously.
 */
export enum CookiesStorageConsentStatus {
  ACCEPTED = 'accepted',
  DECLINED = 'declined',
}

/**
 * The Cookies Local Storage Object.
 */
export interface ICookiesStorage {
  /**
   * The Consent Status.
   */
  consentStatus: CookiesStorageConsentStatus;
}
