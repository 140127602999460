import { Injectable } from '@angular/core';

/**
 * What Local Storage Keys are available?
 */
export enum LocalStorageKeys {
  COOKIES = 'cookies',
}

/**
 * The Local Storage Service provides a wrapper around the Local Storage API.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  /**
   * Is Local Storage Supported?
   */
  private isLocalStorageSupported = false;

  /**
   * Constructor.
   */
  constructor() {
    // if the device has local storage support, then we can use it
    if (this.queryLocalStorageSupport()) {
      this.isLocalStorageSupported = true;
    }
  }

  /**
   * Provides a small test to see if Local Storage is supported.
   *
   * @returns Is Local Storage Supported?
   */
  private queryLocalStorageSupport() {
    try {
      const testKey = '__testKey';
      localStorage?.setItem(testKey, testKey);
      localStorage?.removeItem(testKey);
      return true;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return false;
    }
  }

  /**
   * Set an Item in Local Storage if available.
   *
   * @param key - The key to store the value under.
   * @param value - The value to store.
   */
  setItem(key: LocalStorageKeys, value: string) {
    if (this.isLocalStorageSupported && key && value) {
      localStorage?.setItem(key, value);
    }
  }

  /**
   * Get an Item from Local Storage if available.
   *
   * @param key - The key to retrieve the value from.
   * @returns - The value stored under the key.
   */
  getItem(key: string): string | null {
    return this.isLocalStorageSupported ? localStorage?.getItem(key) : null;
  }

  /**
   * Remove an Item from Local Storage if available.
   *
   * @param key - The key to remove from Local Storage.
   */
  removeItem(key: string) {
    if (this.isLocalStorageSupported && key) {
      localStorage?.removeItem(key);
    }
  }

  /**
   * Clear all items from Local Storage if available.
   */
  clear() {
    if (this.isLocalStorageSupported) {
      localStorage?.clear();
    }
  }
}
