import { LoggerService } from '@angular-ru/cdk/logger';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ICookies } from 'src/app/api/modules/core/components/static/cookies/ICookies';
import { CookiesStorageConsentStatus, ICookiesStorage } from 'src/app/api/modules/core/components/static/cookies/ICookiesStorage';
import { LocalStorageKeys, LocalStorageService } from '../local-storage.service';
import { CookiesRepository } from './cookies.repository';

/**
 * The Cookies Service manages DigitaService Cookies.
 */
@Injectable({
  providedIn: 'root',
})
export class CookiesService {
  /**
   * The Window Object
   */
  private window?: Window;

  /**
   * Constructor
   */
  constructor(
    private readonly loggerService: LoggerService,
    private readonly localStorageService: LocalStorageService,
    public readonly cookieRepository: CookiesRepository,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    this.window = this.document?.defaultView;

    // listen for changes to the cookies enabled state
    this.cookieRepository.enabled$.subscribe((data) => {
      if (data.enabled === true) {
        this.window?.cookiesActivate();
        if (data.localStorage === true) {
          const storage: ICookiesStorage = {
            consentStatus: CookiesStorageConsentStatus.ACCEPTED,
          };
          this.localStorageService.setItem(LocalStorageKeys.COOKIES, JSON.stringify(storage));
        }
      } else if (data.enabled === false) {
        this.window?.cookiesDeactivate();
        if (data.localStorage === true) {
          const storage: ICookiesStorage = {
            consentStatus: CookiesStorageConsentStatus.DECLINED,
          };
          this.localStorageService.setItem(LocalStorageKeys.COOKIES, JSON.stringify(storage));
        }
      }
    });
  }

  /**
   * Apply a Cookie Configuration.
   *
   * @param configuration - The configuration from the server.
   */
  applyConfiguration(configuration?: ICookies) {
    // if there is a configuration and if it wants to invalidate
    // the cookies then do that first before proceeding
    if (configuration?.invalidate === true) {
      // clear the cookies
      this.clearCookies();
    }

    // the locally stored cookies configuration
    let cookiesStorage: ICookiesStorage | null = null;

    // get the cookies string from the local storage
    const cookiesStorageString = this.localStorageService.getItem(LocalStorageKeys.COOKIES);

    // try to convert the cookies from the local storage into an object
    if (cookiesStorageString) {
      try {
        // try to parse the cookies string into an object
        cookiesStorage = JSON.parse(cookiesStorageString) as ICookiesStorage;
      } catch (e) {
        // if there is an error then log it
        this.loggerService.error(e);
      }
    }

    // pass both the optional configuration and the cookies storage to the repository
    this.cookieRepository.applyConfiguration(configuration, cookiesStorage);
  }

  /**
   * Apply User Consent.
   */
  applyConsent() {
    this.cookieRepository.applyConsent();
  }

  /**
   * Reject User Consent.
   */
  rejectConsent() {
    this.cookieRepository.rejectConsent();
  }

  /**
   * Clear the Cookies
   */
  clearCookies() {
    // clear any cookies that are stored in the local storage
    this.localStorageService.removeItem(LocalStorageKeys.COOKIES);

    // clear any cookies in the global window object
    this.window?.cookiesDeactivate();

    // clear any cookies in the repository
    this.cookieRepository.clear();
  }
}
